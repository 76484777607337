import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Fontawesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

// Local styles
import styles from './style.scss';

// Global utils
import {pxToVwMaxed} from '../../utils/utils';

// Global components
import {PulseLoader} from '../Spinner';

// CSS Themes
import COLORS from '../../themes/_colors_v2.module.scss';

const Button = (props) => {
  // Methods
  const getPassingProps = () => {
    const nonAllowedPassingProps = [
      'label',
      'isLoading',
      'isDisabled',
      'className',
      'faLeftIcon',
      'faRightIcon',
      'variant',
      'color',
      'size',
      'scaled',
    ];
    return Object.keys(props).reduce((previousValue, currentValue) => {
      if (!nonAllowedPassingProps.includes(currentValue)) {
        return {...previousValue, [currentValue]: props[currentValue]};
      }
      return previousValue;
    }, {});
  };
  const getButtonContentColorTemplate = (config = {}) => {
    if (props.variant === 'contained') {
      return props.isDisabled
        ? config.secondary
        : props.color === 'primary'
        ? config.secondary
        : config.primary;
    } else {
      return props.isDisabled
        ? config.disabled
        : props.color === 'primary'
        ? config.primary
        : config.secondary;
    }
  };
  // Button main properties
  const getButtonSizingClass = () => {
    switch (props.size) {
      case 'small':
        return styles['HuiButton--small'];
      case 'large':
        return styles['HuiButton--large'];
      case 'medium':
      default:
        return styles['HuiButton--medium'];
    }
  };
  const getButtonColorClass = () => {
    if (props.variant === 'contained') {
      return props.isDisabled
        ? styles['HuiButton--disabled--contained']
        : props.color === 'primary'
        ? styles['HuiButton--primary--contained']
        : styles['HuiButton--secondary--contained'];
    } else {
      return props.isDisabled
        ? styles['HuiButton--disabled--outlined']
        : props.color === 'primary'
        ? styles['HuiButton--primary--outlined']
        : styles['HuiButton--secondary--outlined'];
    }
  };
  // Button label properties
  const getButtonLabelSizingClass = () => {
    switch (props.size) {
      case 'small':
        return styles['HuiButton__label--small'];
      case 'large':
      case 'medium':
      default:
        return styles['HuiButton__label--large'];
    }
  };
  const getButtonLabelColorClass = () => {
    return getButtonContentColorTemplate({
      primary: styles['HuiButton__label--primary'],
      secondary: styles['HuiButton__label--secondary'],
      disabled: styles['HuiButton__label--disabled']
    });
  };
  // Button icon properties
  const getIconSizeClass = () => {
    switch (props.size) {
      case 'small':
        return styles['HuiButton__icon--small'];
      case 'large':
      case 'medium':
      default:
        return styles['HuiButton__icon--large'];
    }
  };
  // Button spinnerproperties
  const getSpinnerSize = () => {
    switch (props.size) {
      case 'small':
        return props.scaled ? pxToVwMaxed(8) : 8;
      case 'large':
        return props.scaled ? pxToVwMaxed(12): 12;
      case 'medium':
      default:
        return props.scaled ? pxToVwMaxed(10): 10;
    }
  };
  // Render
  return (
    <button
      className={clsx(
        styles.HuiButton,
        getButtonSizingClass(),
        getButtonColorClass(),
        props.className,
        props.scaled && styles['HuiButton--scaled'],
      )}
      disabled={props.isDisabled}
      {...getPassingProps()}
    >
      {props.isLoading ? (
        <PulseLoader color={COLORS.neutralPrimary} size={getSpinnerSize()} />
      ) : (
        <Fragment>
          {props.faLeftIcon && (
            <div
              className={clsx(
                styles.HuiButton__icon,
                styles['HuiButton__icon--left'],
                getIconSizeClass(),
                getButtonLabelColorClass()
              )}
            >
              <FontAwesomeIcon icon={props.faLeftIcon} />
            </div>
          )}
          <p
            className={clsx(
              styles.HuiButton__label,
              getButtonLabelSizingClass(),
              getButtonLabelColorClass()
            )}
          >
            {props.label}
          </p>
          {props.faRightIcon && (
            <div
              className={clsx(
                styles.HuiButton__icon,
                styles['HuiButton__icon--right'],
                getIconSizeClass(),
                getButtonLabelColorClass()
              )}
            >
              <FontAwesomeIcon icon={props.faRightIcon} />
            </div>
          )}
        </Fragment>
      )}
    </button>
  );
};

Button.defaultProps = {
  isLoading: false,
  isDisabled: false,
  scaled: false,
  size: 'medium',
  color: 'primary',
  variant: 'contained',
};

Button.propTypes = {
  label: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  scaled: PropTypes.bool,
  className: PropTypes.string,
  faLeftIcon: PropTypes.object,
  faRightIcon: PropTypes.object,
  variant: PropTypes.oneOf(['outlined', 'contained']),
  color: PropTypes.oneOf(['primary', 'secondary']),
  size: PropTypes.oneOf(['small', 'medium', 'large'])
};

export default Button;
